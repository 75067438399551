import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Table, Tooltip } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Header from "../common/header";
import Footer from "../common/footer";
import Userimage from "../../assets/defaultpimage.jpg";
import Copysvg from "../../assets/copy.svg";
import { useJwt } from "react-jwt";
import { BeatLoader } from "react-spinners";
import Modal from "react-bootstrap/Modal";
import MessageConst from "../../const/message.json";
import Filetypecomman from "../common/Filetypecomman";
import detailNFT from "../../assets/NFT_detailBg.png";
import verifyImg from "../../assets/verifyImg.png";
import share from "../../assets/share.svg";
import bithomp from "../../assets/bitomp.svg";
import { useDispatch, useSelector } from "react-redux";
import LazyLoad, { forceVisible } from "react-lazyload";
import Like from "./like/like";
import {
  nftDetailAction,
  tradeHistoryAction,
  updateNftInfoAction,
  addCollection,
  getCollections,
  deleteCollection,
} from "../../store/actions/nftdetail";
import { getBalanceAction } from "../../store/actions/wallet";
import Accordion from "react-bootstrap/Accordion";
import configData from "../../config.json";
import * as Spinners from "react-loader-spinner";

// actions
import {
  mintNftAction,
  burnNftAction,
  saleNftAction,
  cancelSaleNftAction,
  buyNftAction,
  bidTokenAction,
  bidCancelTokenAction,
  deleteNftAction,
  sendNftAction,
  receiveNftAction,
  cancelSendNftAction,
  placeMoreOfferAction,
  getAllPlacedOffersAction,
  acceptPlacedOffersAction,
  cancelPlacedOffersAction,
} from "../../store/actions/mintNFT";

import { getProfileAction } from "../../store/actions/profile";
import { checkImageExists, replaceHost, extractCIDFromURL } from "../../helper";

function Nftdetail() {
  const token = localStorage.getItem("jwtToken");
  const { decodedToken } = useJwt(token);
  const [showMintModel, setShowMintModel] = useState(false);
  const [showSaleModel, setShowSaleModel] = useState(false);
  const [showBidModel, setShowBidModel] = useState(false);
  const [chkValue, setChkValue] = useState(true); /////////// terms and condition check box
  const [royaltyPerc, setRoyaltyPerc] = useState(0); //set royalty perc modal
  const [saleAmountModel, setSaleAmountModel] = useState(null); /////////// Sale amount Model
  const [bidAmountModel, setBidAmountModel] = useState(null); /////////// Bid amount Model
  const [bidDateModel, setBidDateModel] = useState(null); /////////// Bid Date Model
  const [nft, setNft] = useState(null); // set value for nft detail
  const [nftUser, setDatauser] = useState({}); // eslint-disable-line
  // set value for nft user detail
  const [mintinfo, setMintinfo] = useState({}); // set value for nft minted detail
  const [searchKey, setSearchKey] = useState(true);
  const [properties, setProperties] = useState(null);
  const [isSpinner, setIsSpinner] = useState(true);
  const [currency, setCurrency] = useState("");
  const [tokenTicker, setTokenTicker] = useState(null);
  const [onlyXrpFlag, setOnlyXrpFlag] = useState(false);
  const [burnFlag, setBurnFlag] = useState(false);
  const [tranFlag, setTransFlag] = useState(true);
  const [showSendModel, setShowSendModel] = useState(false);
  const [desAddress, setDesAddress] = useState(null);
  const [discModal, setDiscModal] = useState(false);
  const [showMoreOfferModal, setShowMoreOfferModal] = useState(false);
  const [moreOfferAmount, setMoreOfferamount] = useState(null);
  const [allPlacedOffers, setAllPlacedOffers] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);
  const [allProfile, setAllProfile] = useState(null);
  const [collections, setCollections] = useState(null);
  const [isCollection, setIsCollection] = useState(null);
  const [collectVal, setCollectVal] = useState(null);
  const [isCacheCleard, setIsCacheCleard] = useState(false);

  const url = window.location.href;
  const openDiscModal = () => {
    setDiscModal(true);
  };
  const handleCloseDisc = () => setDiscModal(false);
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const navigate = useNavigate();
  let getParams = useParams();
  const { state } = useLocation();

  let dispatch = useDispatch();
  //////////////////////// CHECK box handle start/////////
  const handleCheckBox = () => {
    if (chkValue === false) {
      setChkValue(true);
    } else if (chkValue === true) {
      setChkValue(false);
    }
  };

  const getImageURL = async (url) => {
    const CID = extractCIDFromURL(url);
    return (await checkImageExists(CID)) ? replaceHost(url) : url;
  };

  //////////////////////// CHECK box handle ENd/////////
  //////////////////////// SALE AMOUNT handle start/////////
  const handleSaleAmount = (e) => {
    setSaleAmountModel(e.target.value);
  };
  //////////////////////// SALE AMOUNT handle ENd/////////

  //////////////////////// SALE AMOUNT handle start/////////
  const handleBidAmount = (e) => {
    setBidAmountModel(e.target.value);
  };
  const handleBidDate = (e) => {
    setBidDateModel(e.target.value);
  };
  //////////////////////// SALE AMOUNT handle ENd/////////

  ///////////////// REDUCER //////////////
  const [
    nftdtl,
    mint,
    burn,
    sale,
    cancel,
    buy,
    bid,
    bidCancel,
    bidAndBurn,
    deleteNftReducer,
    tradeHistoryReducer,
    balance,
    send,
    receive,
    cancelSend,
    likeReducer,
    placeMoreoffer,
    getAllPlacedOffers,
    acceptPlacedoffersReducer,
    cancelPlacedOffer,
    updateNftInfo,
    nftCollection,
  ] = useSelector((state) => [
    state.nftDetailReducer,
    state.mintNftReducer,
    state.burnNftReducer,
    state.saleNftReducer,
    state.cancelSaleNftReducer,
    state.buyNftReducer,
    state.bidTokenReducer,
    state.bidCancelReducer,
    state.bidAndBurnTokenReducer,
    state.deleteNftReducer,
    state.tradeHistoryReducer.tradeHistory,
    state.getBalanceReducer,
    state.sendNftReducer,
    state.receiveNftReducer,
    state.cancelSendNftReducer,
    state.nftLikeReducer,
    state.placeMoreofferReducer,
    state.getAllPlacedoffersReducer,
    state.acceptPlacedoffersReducer,
    state.cancelPlacedOffersReducer,
    state.updateNftInfoReducer,
    state.collectionReducer,
  ]);

  ////////////////////////  MODEL START ///////

  //----------------- SALE MODEL START--------------//
  const handleCloseSaleModel = () => setShowSaleModel(false);
  const showSaleModelButton = async () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "saleCheckUserModel",
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "saleCheckBoxValueModel",
      });
      return;
    }
    setShowSaleModel(true);
  };
  //----------------- SALE MODEL END--------------//
  //----------------- BID MODEL START--------------//
  const handleCloseBidModel = () => setShowBidModel(false);
  const showBidModelButton = async () => {
    ///////////////////// FOR DEV-NET ONLY /////////////////////////////////
    toast.info(MessageConst.messageForBidAndBurnFeature, {
      toastId: "cbidAndBurnTokenCheckBoxValue1111",
    });
    return;
    /////////////////////////////// FOR DEV-NET ONLY//////////////////////////
    // check user
    // if (decodedToken.ac !== nft.accountNumber) {
    // 	toast.warn(MessageConst.errorConnectXummWallet, {
    // 		toastId: "bd",
    // 	});
    // 	setTimeout(() => {
    // 		navigate("/Login");
    // 	}, 1000);
    // 	return;
    // }
    // // check checkbox is true or not
    // if (chkValue !== true) {
    // 	toast.warn(MessageConst.warningTermsandConditions, {
    // 		toastId: "bd1",
    // 	});
    // 	return;
    // }
    // setShowBidModel(true);
  };
  //----------------- BID MODEL END--------------//
  ////////////////////////  MODEL END ///////

  useEffect(() => {
    if (!nft) {
      setTimeout(() => {
        forceVisible();
      }, 100);
    }
  }, [nft]);

  /////////////////// REDUCER ALL DATA //////////////////

  useEffect(() => {
    // console.log(nftdtl, "check the adfsdf")
    dispatch(getCollections({ page: null, walletAddress: getParams.id, type: 1 }));
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsSpinner(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!!collections) {
      const data = collections.docs.find(
        (vl) =>
          vl._nftid === getParams?.id && vl.accountNumber === decodedToken?.ac
      );
      // if (collections.docs.length === 0) {
      //   setIsCollection(null);
      //   setCollectVal(null)
      // } else {
      if (!state) {
        // console.log(state, "check the state22", data)
        if (data !== undefined) {
          // console.log(state, "check the state", data)
          setIsCollection(true);
          setCollectVal(data);
        } else {
          // console.log(state, "check the state1", data)
          setIsCollection(false);
          setCollectVal(data);
        }
      } else {
        // console.log(state, "check the state", data)
        if (state.isValid == false) {
          setIsCollection(null);
          setCollectVal(null);
        } else if (data !== undefined) {
          setIsCollection(true);
          setCollectVal(data);
        } else {
          setIsCollection(false);
          setCollectVal(data);
        }
      }
      // }
    }
  }, [collections]);

  useEffect(() => {
    if (!!nftCollection.nftCollections) {
      const { data, message, deleted } = nftCollection.nftCollections;
      if (data) {
        setCollections(data);
      } else {
        if (deleted) {
          toast.error(message, {
            toastId: "deletecollection",
            onClose: () => {
              dispatch(
                getCollections({
                  page: null,
                  walletAddress: getParams.id,
                  type: 1,
                })
              );
            },
          });
        } else {
          toast.success(message, {
            toastId: "addcollection",
            onClose: () => {
              console.log("success", message);
              dispatch(
                getCollections({
                  page: null,
                  walletAddress: getParams.id,
                  type: 1,
                })
              );
            },
          });
        }
      }
    }
  }, [nftCollection]);

  useMemo(() => {
    (async () => {
      if (nftdtl.nftDetailsNFT !== "") {
        const { data, datauser, minData, totalNft } = nftdtl.nftDetailsNFT;
        data.image = await getImageURL(data.image);
        // await getImageURL(data.image);
        
        // console.log(data.image, "check the data111")
        setNft({ ...data, totalNft });
        setDatauser(datauser);
        setMintinfo(minData);
        setProperties(null);
        if (data?.metaDataUrl !== undefined) {
          try {
            const metaUrl = await getImageURL(data.metaDataUrl);
            const res = await fetch(metaUrl);
            const PropVal = await res.json();
            const propData =
              typeof PropVal.attributes === "string"
                ? JSON.parse(PropVal.attributes)
                : PropVal.attributes;
            // console.log(propData, "propData111")

            const arr = [];
            if (PropVal?.collectionName) {
              arr.push({
                traitType: "Collection Name",
                traitValue: PropVal?.collectionName,
              });
            }
            if (PropVal?.type) {
              arr.push({
                traitType: "Type",
                traitValue: PropVal?.type,
              });
            }
            if (PropVal?.externalurl) {
              arr.push({
                traitType: "External Url",
                traitValue: PropVal?.externalurl,
              });
            }

            //  console.log(...arr, "check the arr")
            setProperties([...propData, ...arr]);
          } catch (error) {
            return error;
          }
        }
      }
    })();
  }, [nftdtl]); // eslint-disable-line

  let issuer = "NA";
  let mintedDate = "NA";
  if (mintinfo !== null) {
    issuer = mintinfo?.Issuer;
    mintedDate = new Date(mintinfo?.createdAt).toLocaleDateString(
      "en-US",
      DATE_OPTIONS
    );
  }

  ////////////////////////  ALL DATA FATCH ONLOAD START///////
  useEffect(() => {
    try {
      let itemID = getParams.id;
      dispatch(nftDetailAction({ itemID, loader: true }));
    } catch (error) {
      console.log(error, "error in param");
      toast.error(error.response.data.message, {
        toastId: "Nftdetail1111",
      });
    }
  }, [getParams, likeReducer]); // eslint-disable-line

  ////////////////////////  ALL DATA FATCH ONLOAD END///////

  /* Trade history dispatch action */
  useEffect(() => {
    try {
      let nftId = getParams.id;
      dispatch(tradeHistoryAction({ nftId, loader: true }));
    } catch (error) {
      toast.error(error.response.data.message, {
        toastId: "Nfttradehistory",
      });
    }
  }, [getParams.id]); // eslint-disable-line

  ////////////////////////   FOR BUY START ///////

  useEffect(() => {
    if (buy.error === false) {
      toast.success(buy.buy.message, {
        toastId: "cbuy1",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (buy.error !== null) {
      toast.error(buy.error.message, {
        toastId: "cabuy2",
      });
    }
  }, [buy.error]); // eslint-disable-line

  ////////////////////////  FOR BUY END ///////
  ////////////////////////  CANCEL FOR SALE START ///////
  useEffect(() => {
    if (cancel.error === false) {
      toast.success(cancel.cancelSale.message, {
        toastId: "cancel1",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (cancel.error !== null) {
      toast.error(cancel.error.message, {
        toastId: "cancel2",
      });
    }
  }, [cancel.error]); // eslint-disable-line

  ////////////////////////  CANCEL FOR SALE END ///////
  ////////////////////////  FOR SALE START ///////
  useEffect(() => {
    if (sale.error === false) {
      toast.success(sale.sale.message, {
        toastId: "salemsg1",
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 5000);
    } else if (sale.error !== null) {
      toast.error(sale.error.message, {
        toastId: "salemsg2",
      });
    }
  }, [sale.error]); // eslint-disable-line
  ////////////////////////  FOR SALE END ///////

  ////////////////////////  FOR MINT START ///////
  useEffect(() => {
    if (mint.error === false) {
      toast.success(mint.mint.message, {
        toastId: "simint311",
        onClose: () => window.location.reload(),
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (mint.error !== null) {
      toast.error(mint.error.message, {
        toastId: "simint311",
      });
    }
  }, [mint.error]); // eslint-disable-line

  ////////////////////////  FOR MINT END ///////
  ////////////////////////  FOR BURN START ///////
  useEffect(() => {
    if (burn.error === false) {
      toast.success(burn.mint.message, {
        toastId: "siburn311",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (burn.error !== null) {
      toast.error(burn.error.message, {
        toastId: "siburn311",
      });
    }
  }, [burn.error]); // eslint-disable-line

  ////////////////////////  FOR BURN END ///////

  ////////////////////////  FOR BID START ///////
  useEffect(() => {
    if (bid.error === false) {
      toast.success(bid.bid.message, {
        toastId: "sibid311",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (bid.error !== null) {
      toast.error(bid.error.message, {
        toastId: "sibid311",
      });
    }
  }, [bid.error]); // eslint-disable-line

  ////////////////////////  FOR BID END ///////

  ////////////////////////  FOR CANCEL BID START ///////
  useEffect(() => {
    if (bidCancel.error === false) {
      toast.success(bidCancel.bidCancel.message, {
        toastId: "sigbidCancel311",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (bidCancel.error !== null) {
      toast.error(bidCancel.error.message, {
        toastId: "sigbidCancel311",
      });
    }
  }, [bidCancel.error]); // eslint-disable-line

  ////////////////////////  FOR CANCEL BID END ///////

  ////////////////////////  FOR BID AND BURN START ///////
  useEffect(() => {
    if (bidAndBurn.error === false) {
      toast.success(bidAndBurn.bidAndBurn.message, {
        toastId: "sigbidAndBurn311",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (bidAndBurn.error !== null) {
      toast.error(bidAndBurn.error.message, {
        toastId: "sigbidAndBurn311",
      });
    }
  }, [bidAndBurn.error]); // eslint-disable-line

  ////////////////////////  FOR BID AND BURN END ///////

  // Get Profile Info
  useEffect(() => {
    dispatch(getProfileAction({ wAddress: "" }))
      .then((pDetail) => {
        setAllProfile(pDetail.data.allProfile);
      })
      .catch((err) => console.log(err, "pdetails error"));
  }, []); // eslint-disable-line

  ////////////////////////  FOR BID AND BURN DISPACH START ///////
  const bidAndBurnToken = async () => {
    ///////////////////// FOR DEV-NET ONLY /////////////////////////////////
    toast.info(MessageConst.messageForBidAndBurnFeature, {
      toastId: "cbidAndBurnTokenCheckBoxValue22222",
    });
    return;
    /////////////////////////////// FOR DEV-NET ONLY//////////////////////////
    // check user
    // if (decodedToken.ac !== nft.accountNumber) {
    // 	toast.warn(MessageConst.errorConnectXummWallet, {
    // 		toastId: "bidAndBurnTokenCheckUser",
    // 	});
    // 	setTimeout(() => {
    // 		window.location.reload(false);
    // 	}, 1000);
    // 	return;
    // }
    // check checkbox is true or not
    // if (chkValue !== true) {
    // 	toast.warn(MessageConst.warningTermsandConditions, {
    // 		toastId: "cbidAndBurnTokenCheckBoxValue",
    // 	});
    // 	return;
    // }
    // call api

    // try {
    // 	let data = {
    // 		_id: nft._id,
    // 	};
    // 	dispatch(bidAndBurnTokenAction({ data, loader: true }));
    // } catch (error) {
    // 	toast.error(MessageConst.somethingWrongError, {
    // 		toastId: "bidAndBurnToken1",
    // 	});
    // }
  };
  ////////////////////////  FOR BID AND BURN DISPACH END ///////

  ////////////////////////  FOR CANCEL BID DISPACH START ///////
  const cancelBid = async () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "cancelBidUser",
      });
      setTimeout(() => {
        navigate("/Login");
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "cancelBidBoxValue",
      });
      return;
    }
    // call api
    try {
      let data = {
        _id: nft._id,
      };
      dispatch(bidCancelTokenAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "cancelBid1",
      });
    }
  };
  ////////////////////////  FOR CANCEL BID DISPACH END ///////

  ////////////////////////  FOR BID DISPACH START ///////
  const bidToken = async () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "bidTokenCheckUser",
      });
      setTimeout(() => {
        navigate("/Login");
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "bidTokenCheckBoxValue",
      });
      return;
    }

    // check AMOUNT
    if (bidAmountModel === null) {
      toast.warn(MessageConst.warningEnterPrice, {
        toastId: "sbidAmountModel",
      });
      return;
    }
    // check date and time
    if (bidDateModel === null) {
      toast.warn(MessageConst.warningSelectDateTime, {
        toastId: "sbidDateModel",
      });
      return;
    }

    try {
      let data = {
        _id: nft._id,
        amount: bidAmountModel,
        bidDateAndTime: bidDateModel,
      };
      if (
        Date.parse(new Date()) < Date.parse(bidDateModel) &&
        Date.parse(bidDateModel) <
          Date.parse(new Date(new Date().setDate(new Date().getDate() + 2)))
      ) {
        dispatch(bidTokenAction({ data, loader: true }));
      } else {
        toast.warn(
          MessageConst.warningSelectDateBet +
            new Date() +
            " TO " +
            new Date(new Date().setDate(new Date().getDate() + 2)),
          {
            toastId: "sbidDateModel",
          }
        );
      }
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "bidToken1",
      });
    }
  };
  ////////////////////////  FOR BID DISPACH END ///////
  ////////////////////////  FOR BUY NFT DISPACH START ///////
  const buyNft = async () => {
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "buynft12111",
      });
      return;
    }
    if (decodedToken === null) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "buynft12",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    if (nft.status === "sale") {
      try {
        let data = {
          _id: nft._id,
        };
        dispatch(buyNftAction({ data, loader: true }));
      } catch (error) {
        toast.error(MessageConst.somethingWrongError, {
          toastId: "buynft1",
        });
      }
    } else if (nft.status === "bid" || nft.status === "bidandburn") {
      navigate(`/BidDetail/${nft._id}`);
    } else {
      toast.error(MessageConst.WarningNotOnSale, {
        toastId: "buynft12",
      });
    }
  };
  ////////////////////////  FOR BUY NFT DISPACH END ///////
  ////////////////////////  FOR CANCEL SALE TOKEN DISPACH START ///////
  const cancelSale = async () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "cancelSaleCheckUser",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "cancelSaleCheckBoxValue",
      });
      return;
    }
    // call api
    try {
      let data = {
        _id: nft._id,
      };
      dispatch(cancelSaleNftAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "cancelsaleToken1",
      });
    }
  };
  ////////////////////////  FOR CANCEL SALE TOKEN DISPACH END ///////
  ////////////////////////  FOR SALE TOKEN DISPACH START ///////

  const saleToken = async () => {
    let decimalregex = /^\d{0,12}(\.\d{0,6})?$/;
    let decimalregex1 = /^\d{0,15}(\.\d{0,15})?$/;

    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "saleCheckUser",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "saleCheckBoxValue",
      });
      return;
    }
    // check checkbox is true or not
    if (saleAmountModel === null || saleAmountModel <= 0) {
      toast.warn(MessageConst.warningEnterPrice, {
        toastId: "ssaleAmountModel",
      });
      return;
    }
    if (!currency) {
      toast.warn(MessageConst.SELECT_CURRENCY, {
        toastId: "salecurrency",
      });
      return;
    }
    if (currency === "XRP" && !decimalregex.test(saleAmountModel)) {
      return toast.warn(MessageConst.XRP_WRONG_DECIMAL, {
        toastId: "wrongdecimal",
      });
    }
    if (currency !== "XRP" && !decimalregex1.test(saleAmountModel)) {
      return toast.warn(MessageConst.ISSUED_WRONG_DECIMAL, {
        toastId: "wrongdecimal1",
      });
    }
    const Issuer = tokenTicker.find((i) => {
      return i.curr === currency;
    });

    // call api
    try {
      let data = {
        _id: nft._id,
        amount: saleAmountModel,
        currency: currency,
        issuerAdd: currency === "XRP" ? "" : Issuer.issuer,
      };
      dispatch(saleNftAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "saleToken1",
      });
    }
  };
  ////////////////////////  FOR SALE TOKEN DISPACH END ///////
  ////////////////////////  FOR BURN TOKEN DISPACH START ///////
  const burnToken = async () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "burnCheckUser",
      });
      setTimeout(() => {
        navigate("/Login");
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "burnCheckBoxValue",
      });
      return;
    }
    // call api

    try {
      let data = {
        _id: nft._id,
      };
      dispatch(burnNftAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "burnToken1",
      });
    }
  };
  ////////////////////////  FOR BURN TOKEN DISPACH END ///////
  const handleCloseMintModel = () => setShowMintModel(false);

  const showMintModelButton = () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "saleCheckUserModel",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "saleCheckBoxValueModel",
      });
      return;
    }
    setShowMintModel(true);
  };

  const handleRoyaltyPerc = (e) => {
    setRoyaltyPerc(e.target.value);
  };
  ////////////////////////  FOR MINT TOKEN DISPACH START ///////
  const mintToken = async () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.error(MessageConst.errorConnectXummWallet, {
        toastId: "mintCheckUser",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "mintCheckBoxValue",
      });
      return;
    }

    let flag = await flagHandler(tranFlag, burnFlag, onlyXrpFlag);
    // call api
    try {
      let data = {
        _id: nft._id,
        TransferFee: royaltyPerc * 1000,
        flag: flag,
      };
      dispatch(mintNftAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "mintToken1",
      });
    }
  };

  const flagHandler = async (tranFlag, burnFlag, onlyXrpFlag) => {
    var arr = [];
    if (tranFlag) arr.push(8);
    if (burnFlag) arr.push(1);
    if (onlyXrpFlag) arr.push(2);
    arr.join(", ");
    return arr.reduce((a, b) => a + b, 0);
  };
  ////////////////////////  FOR MINT TOKEN DISPACH END ///////
  // =============DELETE NFT RESPONSE============
  useEffect(() => {
    if (isCacheCleard) {
      if (deleteNftReducer.error === false) {
        toast.success(deleteNftReducer.deletedNft.message, {
          toastId: "simint3111",
          onClose: () => {
            setIsCacheCleard(false);
            navigate("/");
          },
        });
      } else if (deleteNftReducer.error !== null) {
        toast.error(deleteNftReducer.error.message, {
          toastId: "simint3111",
          onClose: () => {
            setIsCacheCleard(false);
          },
        });
      }
    }
  }, [deleteNftReducer.error, isCacheCleard]); // eslint-disable-line

  // ===========DELETE NFT DISPATCH ACTION==========
  const deleteNft = async () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.error(MessageConst.errorConnectXummWallet, {
        toastId: "deleteCheckUser",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "deleteCheckBoxValue",
      });
      return;
    }
    // call api
    try {
      setIsCacheCleard(true);
      let data = {
        _id: nft._id,
      };
      dispatch(deleteNftAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "deleteToken1",
      });
    }
  };

  /* Issued currency for sale feature */
  const handleCurrency = (e) => {
    setCurrency(e.target.value);
  };

  useEffect(() => {
    if (balance?.currency !== null) {
      if (balance?.currency?.currency) {
        setTokenTicker(balance?.currency?.currency);
      } else {
        setTokenTicker([]);
      }
    }
  }, [balance]); // eslint-disable-line

  useEffect(() => {
    try {
      if (localStorage.getItem("jwtToken") !== null) {
        let data = {
          token: localStorage.getItem("jwtToken"),
        };
        dispatch(getBalanceAction({ data, loader: true }));
      }
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "connectWallet1",
      });
    }
  }, [localStorage.getItem("jwtToken")]); // eslint-disable-line

  /* Send NFT functionality */

  const showSendModelButton = () => {
    // toast.info(MessageConst.messageForBidAndBurnFeature, {
    //   toastId: "cbidAndBurnTokenCheckBoxValue1111",
    // });
    // // return;
    // // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "saleCheckBoxValueModel",
      });
      return;
    }
    setShowSendModel(true);
  };

  const handleCloseSendModel = () => setShowSendModel(false);

  const handleSendAdd = (e) => setDesAddress(e.target.value);

  const sendNft = () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "sendCheckUser",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "sendCheckBoxValue",
      });
      return;
    }
    if (desAddress === null || desAddress === "") {
      toast.warn(MessageConst.DADDRESS, {
        toastId: "sendDaddModel",
      });
      return;
    }
    // call api
    try {
      let data = {
        _id: nft._id,
        destAdd: desAddress,
      };
      dispatch(sendNftAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "sendToken1",
      });
    }
  };

  useEffect(() => {
    if (send.error === false) {
      toast.success(send.send.message, {
        toastId: "salemsg1",
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 5000);
    } else if (send.error !== null) {
      toast.error(send.error.message, {
        toastId: "salemsg2",
      });
    }
  }, [send.error]); // eslint-disable-line

  /* Receive NFT functionality */
  const receiveNft = async () => {
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "receivenft12111",
      });
      return;
    }
    if (decodedToken === null) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "receivenft12",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    if (nft.status === "send") {
      try {
        let data = {
          _id: nft._id,
        };
        dispatch(receiveNftAction({ data, loader: true }));
      } catch (error) {
        toast.error(MessageConst.somethingWrongError, {
          toastId: "receivenft1",
        });
      }
    } else if (nft.status === "bid" || nft.status === "bidandburn") {
      navigate(`/BidDetail/${nft._id}`);
    } else {
      toast.error(MessageConst.WarningNotOnSale, {
        toastId: "receivenft12",
      });
    }
  };

  useEffect(() => {
    if (receive.error === false) {
      toast.success(receive.receive.message, {
        toastId: "receive1",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (receive.error !== null) {
      toast.error(receive.error.message, {
        toastId: "receive2",
      });
    }
  }, [receive.error]); // eslint-disable-line

  /* Cancel Send functionality  */

  const cancelSendNft = () => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "cancelSendCheckUser",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    // check checkbox is true or not
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "cancelSendCheckBoxValue",
      });
      return;
    }
    // call api
    try {
      let data = {
        _id: nft._id,
      };
      dispatch(cancelSendNftAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "cancelsendToken1",
      });
    }
  };

  useEffect(() => {
    if (cancelSend.error === false) {
      toast.success(cancelSend.cancelSend.message, {
        toastId: "cancelsend1",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } else if (cancelSend.error !== null) {
      toast.error(cancelSend.error.message, {
        toastId: "cancelsend2",
      });
    }
  }, [cancelSend.error]); // eslint-disable-line

  /* More offer start*/
  const showMoreOfferModalMethod = () => {
    if (chkValue !== true) {
      toast.warn(MessageConst.warningTermsandConditions, {
        toastId: "MoreofferCheckBox",
      });
      return;
    }
    if (decodedToken === null) {
      toast.warn(MessageConst.errorConnectXummWallet, {
        toastId: "MoreofferCheckBox1",
        onClose: () => window.location.reload(),
      });
      return;
    }
    setShowMoreOfferModal(true);
  };

  const handleCloseMoreOfferModal = () => setShowMoreOfferModal(false);

  const handleMoreOfferAmount = (e) => setMoreOfferamount(e.target.value);

  const placeOffer = () => {
    let decimalregex = /^\d{0,12}(\.\d{0,6})?$/;
    let decimalregex1 = /^\d{0,15}(\.\d{0,15})?$/;
    //   // check user
    if (decodedToken.ac === nft.accountNumber) {
      toast.warn(MessageConst.MOREOFFER_SAME_OWNER, {
        toastId: "buyofferuserchaeck",
        onClose: () => window.location.reload(),
      });
      return;
    }

    if (moreOfferAmount === null || moreOfferAmount === "") {
      toast.warn(MessageConst.MOREOFFER_BUY_AMOUNT, {
        toastId: "moreofferbuyamount",
      });
      return;
    }

    if (!currency) {
      toast.warn(MessageConst.SELECT_CURRENCY, {
        toastId: "salecurrency",
      });
      return;
    }
    if (currency === "XRP" && !decimalregex.test(moreOfferAmount)) {
      return toast.warn(MessageConst.XRP_WRONG_DECIMAL, {
        toastId: "wrongdecimal",
      });
    }
    if (currency !== "XRP" && !decimalregex1.test(moreOfferAmount)) {
      return toast.warn(MessageConst.ISSUED_WRONG_DECIMAL, {
        toastId: "wrongdecimal1",
      });
    }
    const Issuer = tokenTicker.find((i) => {
      return i.curr === currency;
    });

    // call api
    try {
      let data = {
        _id: nft._id,
        amount: moreOfferAmount,
        currency: currency,
        issuerAdd: currency === "XRP" ? "" : Issuer.issuer,
      };
      //   // call api
      dispatch(placeMoreOfferAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "sendToken1",
      });
    }
  };

  useEffect(() => {
    if (placeMoreoffer.error === false) {
      toast.success(placeMoreoffer.placeMoreoffer.message, {
        toastId: "placeMoreoffer1",
        onClose: () => window.location.reload(),
      });
    } else if (placeMoreoffer.error !== null) {
      toast.error(placeMoreoffer.error.message, {
        toastId: "placeMoreoffer2",
        onClose: () => window.location.reload(),
      });
    }
  }, [placeMoreoffer.error]); // eslint-disable-line

  /* Dispatch order action */
  useEffect(() => {
    try {
      let nftId = getParams.id;
      dispatch(getAllPlacedOffersAction({ nftId, loader: true }));
    } catch (error) {
      toast.error(error.response.data.message, {
        toastId: "getAllOfferAction",
      });
    }
  }, [getParams.id]); // eslint-disable-line

  useEffect(() => {
    if (getAllPlacedOffers.error === false) {
      setAllPlacedOffers(getAllPlacedOffers.getAllPlacedoffer.totalOffer);
    }
  }, [getAllPlacedOffers]); // eslint-disable-line

  const acceptOffer = (val) => {
    // check user
    if (decodedToken.ac !== nft.accountNumber) {
      toast.warn(MessageConst.ACCEPT_OFFER_OWNER, {
        toastId: "acceptofferCheckUser",
        onClose: () => window.location.reload(),
      });
      return;
    } else {
      try {
        let data = {
          _id: nft._id,
          offerId: val.nftOfferIndex,
          nft_buyer: val.nft_buyer,
          nft_owner: val.nft_owner,
        };
        dispatch(acceptPlacedOffersAction({ data, loader: true }));
      } catch (error) {
        toast.error(MessageConst.somethingWrongError, {
          toastId: "acceptOffer1",
        });
      }
    }
  };

  useEffect(() => {
    if (acceptPlacedoffersReducer.error === false) {
      toast.success(acceptPlacedoffersReducer.acceptPlacedoffer.message, {
        toastId: "acceptMoreoffer1",
        onClose: () => window.location.reload(),
      });
    } else if (acceptPlacedoffersReducer.error !== null) {
      toast.error(acceptPlacedoffersReducer.error.message, {
        toastId: "acceptMoreoffer2",
        onClose: () => window.location.reload(),
      });
    }
  }, [acceptPlacedoffersReducer]); // eslint-disable-line

  // cancel offer
  const cancelplacedOffer = (val) => {
    try {
      let data = {
        _id: val.nftObjId,
        nftOfferIndex: val.nftOfferIndex,
      };
      setIsCancelled(true);
      dispatch(cancelPlacedOffersAction({ data, loader: true }));
    } catch (error) {
      toast.error(MessageConst.somethingWrongError, {
        toastId: "cancelsendToken1",
      });
    }
  };

  // display cancel offer message after cancelation
  useEffect(() => {
    if (isCancelled) {
      if (cancelPlacedOffer.error === false) {
        toast.success(cancelPlacedOffer.cancelPlacedoffer.message, {
          toastId: "cancelbuyoffer1",
          onClose: () => {
            setIsCancelled(false);
            navigate("/");
          },
        });
      } else if (cancelPlacedOffer.error !== null) {
        toast.error(cancelPlacedOffer.cancelPlacedoffer.data.message, {
          toastId: "cancelbuyoffer1",
          onClose: () => {
            setIsCancelled(false);
          },
        });
      }
    }
  }, [cancelPlacedOffer.error, isCancelled]); // eslint-disable-line

  /* Update nft info dispatch action */
  useEffect(() => {
   if(token !== null){ 
    try {
      let nftId = getParams.id;
      dispatch(updateNftInfoAction({ nftId, loader: true }));
    } catch (error) {
      toast.error(error.response.data.message, {
        toastId: "updateNftInfo",
      });
    }
   }
  }, [getParams.id]); // eslint-disable-line

  useEffect(() => {
    if (!!updateNftInfo) {
      const { error } = updateNftInfo;
      if (!!error) {
        if (error) {
          const {
            data: { message },
          } = updateNftInfo.updateNftInfo;
          toast.error(message, {
            toastId: "updatenftinfo",
            onClose: () => {
              navigate("/");
            },
          });
        } else {
          dispatch(updateNftInfoAction({ nftId: getParams.id, loader: true }));
        }
      }
    }
  }, [updateNftInfo]); // eslint-disable-line

  const handleCollection = () => {
    const Id = getParams.id;
    dispatch(addCollection({ nftDetail: null, Id, loader: true }));
  };

  const handleDeleteCollection = (id) => {
    dispatch(deleteCollection({ walletAddress: null, id, loader: true }));
  };

  // const getIpfsImage = (img) => {
  //   const last = img.substring(img.lastIndexOf("/") + 1, img.length);
  //   return last.indexOf('.') === -1 ? img?.split("/ipfs/")?.[1]?.startsWith("ba") ?  img : replaceHost(img) : img
  // }

  const tooltip = (
    <Tooltip id="tooltip">
      Before Accepting an <strong>OFFER</strong> for a NFT,{" "}
      <strong>CANCEL</strong> your <strong>FIXED</strong> sale to unlock and
      receive your XRP reserve.
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Header setSearchKey={setSearchKey} />
      {searchKey && (
        <div className="gradientBg pt-4 DetailBG">
          <>
            <Container className="container">
              {isSpinner ? (
                <Row className="loader-class">
                  <Spinners.MutatingDots
                    visible={true}
                    height="100"
                    width="100"
                    color="#f531e9"
                    secondaryColor="#f531e9"
                    radius="12.5"
                    wrapperStyle={{
                      justifyContent: "center",
                    }}
                  />
                </Row>
              ) : (
                <>
                  <Row className="auth-wrapper App align-items-center  ">
                    <Col xs={12} md={12} className="padding-top-bottom-25">
                      <h3 className="nft-details">NFT Detail</h3>
                    </Col>
                    <Col xs={12} md={12} className="">
                      <h2 className="nft_name my-4 d-md-none d-block ms-3">
                        {nft?.name?.indexOf("#") === -1
                          ? nft?.name
                          : nft?.name?.indexOf(" #") === -1
                          ? nft?.name.split("#")[0] +
                            " #" +
                            nft?.name.split("#")[1]
                          : nft?.name}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="pb-5 mainRow">
                    <Col
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="order-1 order-md-0 order-lg-0 order-sm-0 order-xl-0"
                    >
                      <div className="nft_detail">
                        <div className="nft_Buttons_left d-flex align-items-center">
                          {nft?.NFTokenID ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`https://livenet.xrpl.org/nft/${nft?.NFTokenID}`}
                              className="verify"
                            >
                              <p className="d-flex align-items-center justify-content-between ">
                                <img loading="lazy" src={verifyImg} alt="" />
                                <span>Verify</span>
                              </p>
                            </a>
                          ) : (
                            ""
                          )}
                          {!!nft?.likes_data && (
                            <div className="likeCount">
                              <div className="countLike">
                                <p className="d-flex align-items-center justify-content-between">
                                  <Like post={nft?.likes_data} id={nft._id} />
                                </p>
                              </div>
                            </div>
                          )}
                          <CopyToClipboard
                            onCopy={() =>
                              toast.success("Link Copied", {
                                toastId: "copy",
                              })
                            }
                            text={url}
                          >
                            {
                              // eslint-disable-next-line
                              <a className="share cstmShareBTN">
                                <p className="d-flex align-items-center w-100 mb-0 h-100 justify-content-center">
                                  <img src={share} alt="" />
                                  <span>Share&nbsp;</span>
                                </p>
                              </a>
                            }
                          </CopyToClipboard>
                          {nft?.NFTokenID ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`https://bithomp.com/explorer/${nft?.NFTokenID}`}
                              className="share bitomp"
                            >
                              <p className="d-flex align-items-center w-100 mb-0 h-100 justify-content-center">
                                <img src={bithomp} alt="" />
                              </p>
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                        <h2 className="nft_name mt-4 d-md-block d-none">
                          {nft?.name?.indexOf("#") === -1
                            ? nft?.name
                            : nft?.name?.indexOf(" #") === -1
                            ? nft?.name.split("#")[0] +
                              " #" +
                              nft?.name.split("#")[1]
                            : nft?.name}
                        </h2>
                        <p
                          className="nft_description"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {nft?.description}
                        </p>
                        <Accordion
                          defaultActiveKey={["0", "1", "2", "3"]}
                          alwaysOpen
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>ABOUT</Accordion.Header>
                            <Accordion.Body>
                              <div className="aboutDetail d-flex justify-content-between align-items-center">
                                <div className="owned">
                                  <div className="position-relative cstmAboutLink_CardBox">
                                    <h4>Owned by</h4>
                                    {token !== null ? (
                                      <>
                                        <Link
                                          to={"/Profile/" + nft?.accountNumber}
                                        >
                                          <p>
                                            {nft?.accountNumber?.substring(
                                              0,
                                              9
                                            )}
                                          </p>
                                        </Link>
                                        <i
                                          class="fa fa-link cstmFaLinkIcon"
                                          style={{ fontSize: 24 }}
                                        />{" "}
                                      </>
                                    ) : (
                                      <p>
                                        {nft?.accountNumber?.substring(0, 9)}
                                      </p>
                                    )}
                                  </div>
                                  <img
                                    src={
                                      allProfile?.find(
                                        (vl) =>
                                          vl.wAddress === nft?.accountNumber
                                      )?.pImage
                                        ? allProfile
                                            ?.find(
                                              (vl) =>
                                                vl.wAddress ===
                                                nft?.accountNumber
                                            )
                                            ?.pImage?.startsWith("https://ipfs")
                                          ? replaceHost(
                                              allProfile?.find(
                                                (vl) =>
                                                  vl.wAddress ===
                                                  nft?.accountNumber
                                              )?.pImage
                                            )
                                          : `${
                                              configData.LOCAL_API_URL +
                                              allProfile?.find(
                                                (vl) =>
                                                  vl.wAddress ===
                                                  nft?.accountNumber
                                              )?.pImage
                                            }`
                                        : Userimage
                                    }
                                    alt=""
                                    className="rounded-circle width-25"
                                  />
                                </div>
                                <div className="mintBy">
                                  <div className="position-relative cstmAboutLink_CardBox">
                                    <h4>Minted by</h4>
                                    {token !== null ? (
                                      <>
                                        <Link to={"/Profile/" + issuer}>
                                          <p>{issuer?.substring(0, 9)}</p>
                                        </Link>
                                        <i
                                          class="fa fa-link"
                                          style={{ fontSize: 24 }}
                                        />
                                      </>
                                    ) : (
                                      <p>{issuer?.substring(0, 9)}</p>
                                    )}
                                  </div>
                                  <img
                                    src={
                                      allProfile?.find(
                                        (vl) =>
                                          vl.wAddress === nft?.accountNumber
                                      )?.pImage === null
                                        ? allProfile
                                            ?.find(
                                              (vl) =>
                                                vl.wAddress ===
                                                nft?.accountNumber
                                            )
                                            ?.pImage?.startsWith("https://ipfs")
                                          ? replaceHost(
                                              allProfile?.find(
                                                (vl) =>
                                                  vl.wAddress ===
                                                  nft?.accountNumber
                                              )?.pImage
                                            )
                                          : `${
                                              configData.LOCAL_API_URL +
                                              allProfile?.find(
                                                (vl) =>
                                                  vl.wAddress ===
                                                  nft?.accountNumber
                                              )?.pImage
                                            }`
                                        : Userimage
                                    }
                                    alt=""
                                    className="rounded-circle width-25"
                                  />
                                </div>
                                <div className="collectible">
                                  <h4>Collection</h4>
                                  <p>
                                    {!!nft && nft?.totalNft != undefined
                                      ? nft?.NumOfCopies + "/" + nft?.totalNft
                                      : 0 + "/" + 0}
                                  </p>
                                  <img
                                    src={Copysvg}
                                    alt=""
                                    className="rounded-circle width-25"
                                  />
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>DETAILS</Accordion.Header>
                            <Accordion.Body>
                              <div className="nft_detail">
                                <Row>
                                  <Col className="text-left">
                                    <span>Issuer Address</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>{issuer?.substring(0, 20)}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>Token Currency</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>
                                      {nft?.price} {nft?.currency}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>NFT Standard</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>XLS-20</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>Blockchain</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>XRPL</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>File Type</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>{nft?.fileType}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>Content Type</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>{nft?.contentType}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>
                                      Nft ID{" "}
                                      {nft?.NFTokenID ? (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={`https://bithomp.com/explorer/${nft?.NFTokenID}`}
                                        >
                                          {" "}
                                          <img src={bithomp} alt="" />
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>
                                      {nft?.NFTokenID
                                        ? `${nft?.NFTokenID?.substring(
                                            0,
                                            8
                                          )}***${nft?.NFTokenID?.substring(
                                            (nft?.NFTokenID).length - 8
                                          )}`
                                        : "NA"}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>Flag</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>
                                      {mintinfo?.Flags
                                        ? `${
                                            mintinfo?.Flags === "11"
                                              ? "Transferable , Burnable , OnlyXRP"
                                              : mintinfo?.Flags === "10"
                                              ? "Transferable , OnlyXRP"
                                              : mintinfo?.Flags === "9"
                                              ? "Transferable , Burnable"
                                              : "Transferable"
                                          }`
                                        : "NA"}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-left">
                                    <span>Minted On</span>
                                  </Col>
                                  <Col className="text-right">
                                    <p>{mintedDate}</p>
                                  </Col>
                                </Row>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>HISTORY</Accordion.Header>
                            <Accordion.Body>
                              <div className="history_detail">
                                <Table bordered hover className="mb-0">
                                  <thead>
                                    <tr>
                                      <th>Price(All)</th>
                                      <th>Address</th>
                                      <th>Date</th>
                                    </tr>
                                  </thead>
                                  <tbody className="tbody-details">
                                    {!!tradeHistoryReducer &&
                                    tradeHistoryReducer.history.length > 0 ? (
                                      tradeHistoryReducer.history.map(
                                        (val, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>
                                                {val.price} {val.currency}
                                              </td>
                                              <td>
                                                {val.accountAdd.substring(0, 9)}{" "}
                                                *****{" "}
                                                {val.accountAdd.substring(
                                                  val.accountAdd.length - 5
                                                )}
                                              </td>
                                              <td>
                                                {new Date(
                                                  val.createdAt
                                                ).toLocaleDateString(
                                                  "en-US",
                                                  DATE_OPTIONS
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={3} align="center">
                                          No Data Found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              All Buy Offers{" "}
                              {/* {allPlacedOffers?.length > 0 ? (
                                <p className="offer-info">
                                  &nbsp;&nbsp;<sup>*</sup>{" "}
                                  <span>
                                    NFT OWNER :<br />
                                    Before ACCEPTING a ‘OFFER’ for a NFT, CANCEL
                                    your FIXED sale to unlock and receive your XRP
                                    reserve..
                                  </span>
                                </p>
                              ) : (
                                ""
                              )} */}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="history_detail">
                                <Table bordered hover className="mb-0">
                                  <thead>
                                    <tr>
                                      <th>Token</th>
                                      <th>Price</th>
                                      {/* <th>Date</th> */}
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody className="tbody-details">
                                    {!!allPlacedOffers &&
                                    allPlacedOffers.length > 0 ? (
                                      allPlacedOffers.map((val) => {
                                        return (
                                          <tr>
                                            <td className="text-wrap">
                                              {val.buy_curr}
                                              {/* {val.nft_buyer.substring(0, 9)} ***** {val.nft_buyer.substring(val.nft_buyer.length - 5)} */}
                                            </td>
                                            <td className="text-wrap">
                                              {val.buy_amount}
                                            </td>
                                            {/* <td class="text-wrap">
                                          {new Date(
                                            val.createdAt
                                          ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                          )}
                                        </td> */}
                                            <td>
                                              {val?.nft_owner ===
                                              decodedToken.ac ? (
                                                nft?.status === "sale" ? (
                                                  <div className="align">
                                                    {" "}
                                                    {/* <i className="fa fa-check check-disable-icon"></i> */}
                                                    {/* <OverlayTrigger placement="top" overlay={tooltip}> */}
                                                    <Button
                                                      variant="success"
                                                      className="btn-offer-disabled"
                                                    >
                                                      Accept
                                                    </Button>
                                                    {/* </OverlayTrigger> */}
                                                    <small className="zerofees">
                                                      0% Fee
                                                    </small>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {" "}
                                                    <Button
                                                      variant="success"
                                                      className="btn-offer-disabled"
                                                      onClick={() =>
                                                        acceptOffer(val)
                                                      }
                                                    >
                                                      Accept
                                                    </Button>
                                                    {/* <i
                                                      className="fa fa-check check-icon"
                                                      onClick={() =>
                                                        acceptOffer(val)
                                                      }
                                                    ></i>{" "} */}
                                                    <small className="zerofees">
                                                      0% Fee
                                                    </small>
                                                  </div>
                                                )
                                              ) : null}
                                              &nbsp;&nbsp;
                                              {val.nft_id === nft?.NFTokenID &&
                                                val.nft_buyer ===
                                                  decodedToken.ac && (
                                                  <div className="align">
                                                    <Button
                                                      variant="danger"
                                                      className="btn-offer-disabled"
                                                      onClick={() =>
                                                        cancelplacedOffer(val)
                                                      }
                                                    >
                                                      Cancel
                                                    </Button>
                                                    {/* <i
                                                      className="fa fa-times times-icon"
                                                      onClick={() =>
                                                        cancelplacedOffer(val)
                                                      }
                                                    ></i> */}
                                                    <small className="zerofees">
                                                      0% Fee
                                                    </small>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={3} align="center">
                                          No Data Found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="d-flex flex-column align-items-end order-0 order-md-1 order-lg-1 order-sm-1 order-xl-1 mb-5 "
                    >
                      <div className="nft_main w-100">
                        <img
                          src={detailNFT}
                          alt=""
                          className="detailNFT d-none d-md-block d-lg-block d-sm-block d-xl-block"
                        />
                        <div className="image_type">
                          {!nft ? (
                            <Spinners.ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{
                              justifyContent: "center",
                              position: "absolute",
                              top: "40%",
                              left: "63%"
                            }}
                            // wrapperClass="color-ring-wrapper"
                            colors={['#f531e9', '#f531e9', '#f531e9', '#f531e9', '#f531e9']}
                            />
                          ) : (
                            <Filetypecomman
                              fileType={nft?.contentType}
                              image={nft?.image}
                            />
                          )}
                        </div>
                      </div>
                      <div className="nft_Price_Type">
                        <h4>{nft?.currency ? `${nft.currency}` : "XRP"}</h4>
                        <h4> {nft?.price}</h4>
                      </div>
                      <div className="nft_property">
                        <h3>Property</h3>
                        {!!properties && properties.length > 0 ? (
                          properties?.map((val, i) => {
                            return (
                              <div
                                className="property_detail d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <p>{val?.traitType}</p>
                                <p>{val?.traitValue}</p>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-white" style={{ fontSize: 14 }}>
                            No Record Found
                          </p>
                        )}
                      </div>
                      <div className="button_box">
                        <div className="detail-page-lightfont">
                          <input
                            type="checkbox"
                            name="checkbox"
                            onChange={handleCheckBox}
                            className="checkbox-privacy-policy"
                            checked={chkValue}
                          ></input>
                          <span>
                            {" "}
                            I have read and agree to terms of{" "}
                            <b
                              onClick={openDiscModal}
                              style={{ color: "#000", cursor: "pointer" }}
                            >
                              FUZION-XIO
                            </b>
                          </span>
                        </div>
                        <div className="detailsBtn d-flex">
                          {(() => {
                            if (
                              nft?.status === "created" &&
                              token !== null &&
                              decodedToken?.ac === nft?.accountNumber
                            ) {
                              return (
                                <>
                                  <Button
                                    variant="primary"
                                    onClick={showMintModelButton}
                                    className="BuyNFT-button width-48"
                                  >
                                    Mint NFT
                                  </Button>
                                  &nbsp;
                                  <Button
                                    variant="danger"
                                    onClick={deleteNft}
                                    className="BuyNFT-button width-48"
                                  >
                                    {deleteNftReducer.isSubmit ? (
                                      <BeatLoader
                                        sizeUnit="px"
                                        size={10}
                                        color="#FFF"
                                        loading
                                      />
                                    ) : (
                                      "Delete NFT"
                                    )}
                                  </Button>
                                </>
                              );
                            } else if (
                              nft?.status === "minted" &&
                              token !== null &&
                              decodedToken?.ac === nft?.accountNumber
                            ) {
                              return (
                                <>
                                  <Button
                                    variant="danger"
                                    onClick={burnToken}
                                    className="BuyNFT-button"
                                  >
                                    {burn.isSubmit ? (
                                      <BeatLoader
                                        sizeUnit="px"
                                        size={10}
                                        color="#FFF"
                                        loading
                                      />
                                    ) : (
                                      "Burn NFT"
                                    )}
                                  </Button>

                                  <Button
                                    variant="success"
                                    onClick={showSaleModelButton}
                                    className="BuyNFT-button"
                                  >
                                    Sale
                                  </Button>

                                  <Button
                                    variant="info"
                                    onClick={showBidModelButton}
                                    className="BuyNFT-button text-white"
                                  >
                                    {" "}
                                    Bid
                                  </Button>

                                  <Button
                                    variant="primary"
                                    onClick={bidAndBurnToken}
                                    className="BuyNFT-button"
                                  >
                                    {bidAndBurn.isSubmit ? (
                                      <BeatLoader
                                        sizeUnit="px"
                                        size={10}
                                        color="#FFF"
                                        loading
                                      />
                                    ) : (
                                      "Bid and Burn"
                                    )}
                                  </Button>
                                  <Button
                                    variant=""
                                    onClick={showSendModelButton}
                                    className="BuyNFT-button width-100 sendNFT"
                                  >
                                    Send
                                  </Button>
                                </>
                              );
                            } else if (
                              nft?.status === "sale" &&
                              token !== null &&
                              decodedToken?.ac === nft?.accountNumber
                            ) {
                              return (
                                <>
                                  <Button
                                    variant="danger"
                                    onClick={cancelSale}
                                    className="BuyNFT-button width-100 cancelNft"
                                  >
                                    {cancel.isSubmit ? (
                                      <BeatLoader
                                        sizeUnit="px"
                                        size={10}
                                        color="#FFF"
                                        loading
                                      />
                                    ) : (
                                      "Cancel Sale"
                                    )}
                                  </Button>
                                </>
                              );
                            } else if (
                              nft?.status === "send" &&
                              token !== null &&
                              decodedToken?.ac === nft?.accountNumber
                            ) {
                              return (
                                <>
                                  <Button
                                    variant="danger"
                                    onClick={cancelSendNft}
                                    className="BuyNFT-button width-100 cancelNft"
                                  >
                                    {cancelSend.isSubmit ? (
                                      <BeatLoader
                                        sizeUnit="px"
                                        size={10}
                                        color="#FFF"
                                        loading
                                      />
                                    ) : (
                                      "Cancel Send"
                                    )}
                                  </Button>
                                </>
                              );
                            } else if (
                              (nft?.status === "bid" ||
                                nft?.status === "bidandburn") &&
                              token !== null &&
                              decodedToken?.ac === nft?.accountNumber
                            ) {
                              return (
                                <>
                                  <Button
                                    variant="danger"
                                    onClick={cancelBid}
                                    className="BuyNFT-button width-100"
                                  >
                                    {cancel.isSubmit ? (
                                      <BeatLoader
                                        sizeUnit="px"
                                        size={10}
                                        color="#FFF"
                                        loading
                                      />
                                    ) : (
                                      "Cancel Bid"
                                    )}
                                  </Button>
                                </>
                              );
                            } else if (nft?.status === "send") {
                              return (
                                <Button
                                  variant="success"
                                  onClick={receiveNft}
                                  className="BuyNFT-button width-100 cancelNft"
                                >
                                  {receive.isSubmit ? (
                                    <BeatLoader
                                      sizeUnit="px"
                                      size={10}
                                      color="#FFF"
                                      loading
                                    />
                                  ) : (
                                    "Receive"
                                  )}
                                </Button>
                              );
                            } else {
                              return (
                                <>
                                  <Button
                                    variant="success"
                                    onClick={buyNft}
                                    className="BuyNFT-button width-100 cancelNft"
                                  >
                                    {buy.isSubmit ? (
                                      <BeatLoader
                                        sizeUnit="px"
                                        size={10}
                                        color="#FFF"
                                        loading
                                      />
                                    ) : (
                                      "Buy"
                                    )}
                                  </Button>

                                  <Button
                                    variant="success"
                                    onClick={showMoreOfferModalMethod}
                                    className="BuyNFT-button width-100 cancelNft"
                                  >
                                    Place offer
                                  </Button>
                                </>
                              );
                            }
                          })()}
                        </div>
                        {/* {console.log("collection111", isCollection)} */}
                        {isCollection !== null &&
                          (isCollection ? (
                            <Button
                              variant="danger"
                              onClick={() =>
                                handleDeleteCollection(collectVal._id)
                              }
                              className="BuyNFT-button width-100 cancelNft"
                            >
                              {nftCollection.isSubmit ? (
                                <BeatLoader
                                  sizeUnit="px"
                                  size={10}
                                  color="#FFF"
                                  loading
                                />
                              ) : (
                                "Remove NFT from Profile"
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="success"
                              onClick={handleCollection}
                              className="BuyNFT-button width-100 cancelNft"
                            >
                              {nftCollection.isSubmit ? (
                                <BeatLoader
                                  sizeUnit="px"
                                  size={10}
                                  color="#FFF"
                                  loading
                                />
                              ) : (
                                "ADD NFT to Profile"
                              )}
                            </Button>
                          ))}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {/* mint modal start*/}
              <Modal
                show={showMintModel}
                onHide={handleCloseMintModel}
                className="nftDetailModal"
              >
                <br />
                <Modal.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="img-center">
                        <Filetypecomman
                          fileType={nft?.contentType}
                          image={
                            nft?.image ? replaceHost(nft?.image) : nft?.image
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row className="modalRow">
                    <Col>
                      <Form.Check
                        type="switch"
                        label="Transferable"
                        checked={tranFlag}
                        onChange={(e) => {
                          setTransFlag(e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="switch"
                        label="Burnable"
                        checked={burnFlag}
                        onChange={(e) => {
                          setBurnFlag(e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="switch"
                        label="OnlyXRP"
                        checked={onlyXrpFlag}
                        onChange={(e) => {
                          setOnlyXrpFlag(e.target.checked);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12}>
                      <label className="label-uppercase">
                        ADD ROYALTY 0-50%
                      </label>
                      <RangeSlider
                        value={royaltyPerc}
                        onChange={handleRoyaltyPerc}
                        min={0}
                        max={50}
                        tooltipLabel={(currentValue) => `${currentValue}%`}
                        tooltip="on"
                        className=""
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12} className="margin-top1">
                      <Button
                        variant="primary"
                        onClick={mintToken}
                        type="submit"
                        className="form-control margin-top1"
                      >
                        {mint.isSubmit ? (
                          <BeatLoader
                            sizeUnit="px"
                            size={10}
                            color="#FFF"
                            loading
                          />
                        ) : (
                          "Mint NFT"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
                <br />
              </Modal>
              {/* mint modal end */}

              {/* Sale Modal start*/}
              <Modal
                show={showSaleModel}
                onHide={handleCloseSaleModel}
                className="nftDetailModal"
              >
                <br />
                <Modal.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="img-center">
                        <Filetypecomman
                          fileType={nft?.contentType}
                          image={
                            nft?.image ? replaceHost(nft?.image) : nft?.image
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={6}>
                      <input
                        type="number"
                        name="saleAmount"
                        value={saleAmountModel}
                        onChange={handleSaleAmount}
                        className="form-control"
                        placeholder="Enter Amount"
                      ></input>
                    </Col>
                    <Col xs={6} md={6}>
                      <Form.Select
                        onChange={handleCurrency}
                        name="Currency"
                        aria-label="Default select example"
                        id="currency-dropdown"
                      >
                        <option value="">
                          {!!tokenTicker && tokenTicker.length > 0
                            ? "Currency"
                            : "No Currency"}
                        </option>
                        {!!mintinfo && ["10", "11"].includes(mintinfo.Flags) ? (
                          <option key="key" value="XRP">
                            XRP
                          </option>
                        ) : (
                          !!tokenTicker &&
                          tokenTicker.map((curr, index) => (
                            <option key={index} value={curr.curr}>
                              {curr.currency}
                            </option>
                          ))
                        )}
                      </Form.Select>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12}>
                      <Button
                        variant="primary"
                        onClick={saleToken}
                        type="submit"
                        className="form-control"
                      >
                        {sale.isSubmit ? (
                          <BeatLoader
                            sizeUnit="px"
                            size={10}
                            color="#FFF"
                            loading
                          />
                        ) : (
                          "Sale NFT"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
                <br />
              </Modal>
              {/* </> */}

              {/* <> */}
              <Modal
                show={showBidModel}
                onHide={handleCloseBidModel}
                className="nftDetailModal"
              >
                <br />
                <Modal.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="img-center">
                        <Filetypecomman
                          fileType={nft?.contentType}
                          image={
                            nft?.image ? replaceHost(nft?.image) : nft?.image
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12}>
                      <input
                        type="number"
                        name="bidAmount"
                        value={bidAmountModel}
                        onChange={handleBidAmount}
                        className="form-control"
                        placeholder="Enter Amount"
                      ></input>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12}>
                      <input
                        type="datetime-local"
                        name="bidDate"
                        min={new Date()}
                        max={
                          new Date(new Date().setDate(new Date().getDate() + 2))
                        }
                        value={bidDateModel}
                        onChange={handleBidDate}
                        className="form-control"
                        placeholder="Date And Time"
                      ></input>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12} md={12}>
                      <Button
                        variant="primary"
                        onClick={bidToken}
                        type="submit"
                        className="form-control"
                      >
                        {bid.isSubmit ? (
                          <BeatLoader
                            sizeUnit="px"
                            size={10}
                            color="#FFF"
                            loading
                          />
                        ) : (
                          "Bid"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
                <br />
              </Modal>
              {/* </> */}

              {/* Send Modal Start  */}
              <Modal
                show={showSendModel}
                onHide={handleCloseSendModel}
                className="nftDetailModal"
              >
                <Modal.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="img-center">
                        <Filetypecomman
                          fileType={nft?.contentType}
                          image={
                            nft?.image ? replaceHost(nft?.image) : nft?.image
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Col xs={12} md={12}>
                    <input
                      type="text"
                      name="dAddress"
                      value={desAddress}
                      onChange={handleSendAdd}
                      className="form-control"
                      placeholder="Destination Wallet Address"
                    ></input>
                  </Col>

                  <br />
                  <Row>
                    <Col xs={12} md={12}>
                      <Button
                        variant="primary"
                        onClick={sendNft}
                        type="submit"
                        className="form-control"
                      >
                        {send.isSubmit ? (
                          <BeatLoader
                            sizeUnit="px"
                            size={10}
                            color="#FFF"
                            loading
                          />
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
                <br />
              </Modal>

              {/* Terms and conditions modal  */}
              <Modal
                show={discModal}
                onHide={handleCloseDisc}
                backdrop="static"
                className="privacyModal"
              >
                <Modal.Header closeButton className="px-md-5 px-lg-5 px-xl-5">
                  <Modal.Title>
                    <strong className="text-info">NFT DISCLAIMER</strong>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-md-5 px-lg-5 px-xl-5 discBody">
                  <ul>
                    <li>
                      <p>
                        By creating/minting an NFT on the RaDical-X application
                        or smart device applications, you (the user) take full
                        custody of the initial creation and are responsible for
                        its design, illustration, 3D video or audio or anything
                        similar displayed. By agreeing to this disclaimer you
                        remove all liabilities of the design from the RaDical-X
                        application and connections to its creators, founders
                        and partners.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Design you create/mint must be your own personal
                        design, illustration, 3D video or audio. If you are
                        using content from another creator, company logo,
                        commercial brand or anything similar created by someone
                        else you must ask permission to use their content and
                        receive written consent before creating your NFT.
                      </p>
                    </li>
                    <li>
                      <p>
                        Purchasing an NFT off the secondary market, or otherwise
                        acquiring the NFT through any other legitimate means or
                        method, the Holder receives full and complete ownership,
                        inclusive of commercial rights, to the NFT and the
                        corresponding unique artwork.
                      </p>
                    </li>
                    <li>
                      <p>
                        The License the Holder is receiving is solely for the
                        Licensed NFT which includes the right to use the NFT,
                        and the right to reproduce the NFT on tribute or
                        derivative art, merchandise, or sharing these rights
                        with third party projects.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Creator agrees not to use, utilize, portray,
                        advertise, distribute or otherwise market any NFT in any
                        project or derivative work that involves hate speech,
                        racism, pornography, or any other illegal or unlawful
                        content. Upon sale or transfer of the NFT, any ownership
                        or commercial rights are immediately transferred to the
                        new Holder.
                      </p>
                    </li>
                    <li>
                      <p>
                        No refunds shall be issued to any Holder upon a full and
                        complete lawful purchase of any NFT. In the event that
                        any Holder purchases an NFT through the secondary
                        market, the holder shall be held accountable and will be
                        bound by the Terms of Service which govern said
                        secondary market platform.
                      </p>
                    </li>
                    <li>
                      <p>
                        NFT’s may bear elements of transformative fan art or
                        caricatures which are rendered in good faith to add
                        humour and satire to the project. Any Holder of an NFT
                        bearing these elements has an individual responsibility
                        to determine the appropriateness of subsequent usage.
                        Any Attributes associated to an NFT is used as a parody.
                        These attributes are not sponsored, endorsed by or
                        affiliated by any affiliated companies and/or third
                        party licensors.
                      </p>
                    </li>
                    <li>
                      <p>
                        Participants in creating/minting NFTs agree to hold the
                        project Creative Team harmless for any losses incurred
                        as a consequence of creating/minting an NFT. These
                        potential losses include any fees for failed
                        transactions, any excessive fees charged due to
                        website/application or smart contract issues, and any
                        loss of any NFT’s due to website/application or smart
                        contract malfunctions.
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        NFTs are created purely as collectibles, not as
                        investment vehicles or substitutes for cryptocurrency.
                        We make absolutely no promise or guarantee that these
                        NFTs will subsequently retain monetary value in fiat,
                        cash or cryptocurrency.
                      </p>
                    </li>
                    <li>
                      <p>
                        Each Holder is solely and entirely responsible for any
                        and all Federal or State tax liabilities which may
                        arise, be imposed, or enforced as a result of minting or
                        reselling NFTs on the Fuzion-XIO website/application.
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        You agree to waive any class action status, and any
                        legal dispute around the Fuzion-XIO Application which
                        you may choose to bring, can only be done on an
                        individual basis.
                      </p>
                    </li>
                  </ul>
                </Modal.Body>
              </Modal>

              {/* More Offer Modal Start  */}
              <Modal
                show={showMoreOfferModal}
                onHide={handleCloseMoreOfferModal}
                className="nftDetailModal"
              >
                <Modal.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="img-center">
                        <h5>Create Buy Offer</h5>
                        <Filetypecomman
                          fileType={nft?.contentType}
                          image={
                            nft?.image ? replaceHost(nft?.image) : nft?.image
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col xs={6} md={6}>
                      <input
                        type="number"
                        name="saleAmount"
                        value={moreOfferAmount}
                        onChange={handleMoreOfferAmount}
                        className="form-control"
                        placeholder="Enter Amount"
                      ></input>
                    </Col>
                    <Col xs={6} md={6}>
                      <Form.Select
                        onChange={handleCurrency}
                        name="Currency"
                        aria-label="Default select example"
                        id="currency-dropdown"
                      >
                        <option value="">
                          {!!tokenTicker && tokenTicker.length > 0
                            ? "Currency"
                            : "No Currency"}
                        </option>
                        {!!mintinfo && ["10", "11"].includes(mintinfo.Flags) ? (
                          <option key="key" value="XRP">
                            XRP
                          </option>
                        ) : (
                          !!tokenTicker &&
                          tokenTicker.map((curr, index) => (
                            <option key={index} value={curr.curr}>
                              {curr.currency}
                            </option>
                          ))
                        )}
                      </Form.Select>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col xs={12} md={12}>
                      <Button
                        variant="primary"
                        onClick={placeOffer}
                        type="submit"
                        className="form-control"
                      >
                        {placeMoreoffer.isSubmit ? (
                          <BeatLoader
                            sizeUnit="px"
                            size={10}
                            color="#FFF"
                            loading
                          />
                        ) : (
                          "Place Offer"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
                <br />
              </Modal>
            </Container>
          </>
        </div>
      )}
      <Footer />
    </React.Fragment>
  );
}

export default Nftdetail;
